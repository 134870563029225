import {Component, HostListener, NgZone} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {UiService} from '../shared/services/ui.service';
import {Title} from "@angular/platform-browser";
import {ConfigService} from "../core/config";
import {registerLocaleData} from "@angular/common";
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localeIt from '@angular/common/locales/it';
import localeEs from '@angular/common/locales/es';
import localeAr from '@angular/common/locales/ar';
import {takeWhile} from "rxjs";
import {Utils} from "../shared/utils/generics.utils";
import {EventTrackerService} from "../shared/services/event-tracker.service";
import {CookiesConsentService} from "../shared/components/cookies-consent/services/cookies-consent.service";
import * as Sentry from "@sentry/angular";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {MatomoTracker} from "ngx-matomo-client";
import {ActivatedRoute} from "@angular/router";
import 'moment/min/locales';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: false
})
export class AppComponent {

  private readonly LANGUAGE_KEY = 'lang';
  private readonly defaultLanguage = 'en';

  constructor(public translate: TranslateService,
              private titleService: Title,
              private config: ConfigService,
              public ui: UiService,
              private tracker: EventTrackerService,
              private matomoTracker: MatomoTracker,
              private cookies: CookiesConsentService,
              private route: ActivatedRoute,
              private ngZone: NgZone) {

    this.route.queryParamMap.pipe(takeUntilDestroyed()).subscribe((params) => {
      this.ui.setLanguage(params.get(this.LANGUAGE_KEY));
    })

    // Set title
    const subDomain = Utils.getCompanyFromDomain();
    this.titleService.setTitle(Utils.capitalizeFirstLetter(subDomain));

    // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang(this.defaultLanguage);

    // Register locale data
    if (this.config.getConfig().webapp.languages.includes('fr')) {
      registerLocaleData(localeFr)
    }
    if (this.config.getConfig().webapp.languages.includes('de')) {
      registerLocaleData(localeDe)
    }
    if (this.config.getConfig().webapp.languages.includes('it')) {
      registerLocaleData(localeIt)
    }
    if (this.config.getConfig().webapp.languages.includes('es')) {
      registerLocaleData(localeEs)
    }
    if(this.config.getConfig().webapp.languages.includes('ar')) {
      registerLocaleData(localeAr);
    }

    this.cookies.cookieChanges$.pipe(
      takeUntilDestroyed(),
      // stop if user accepts, because we cannot remove it anyway
      takeWhile((preferences) => !preferences.functionality.value, true)
    ).subscribe((preferences) => {
      // else freezes on landing button click
      this.ngZone.runOutsideAngular(() => {
        if (!preferences.performance.value) {
          this.matomoTracker.deleteCustomVariable(1, "visit");
          Sentry.setUser(null);
          Sentry.setTag("vehicle_id", undefined);
        }
      })
    });
  }

  @HostListener('window:beforeunload')
  onBeforeunload() {
    this.tracker.trackEvent('Navigation', 'Window close', undefined);
  }

  @HostListener('window:focus', ['$event'])
  onFocus() {
    this.tracker.trackEvent('Navigation', 'Window focus', undefined);
  }

  @HostListener('window:blur', ['$event'])
  onBlur() {
    this.tracker.trackEvent('Navigation', 'Window blur', undefined);
  }
}
