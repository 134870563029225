import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {BrowserGuard} from './core/guards/browser/browser.guard';
import {ContextGuard} from "./core/guards/context/context.guard";
import {ProUrlResolver} from "./features/products/pro/resolvers/pro-url.resolver";
import {Context} from "./core/models/context";
import {CompanyResolver} from "./core/resolvers/company.resolver";

// Caution order change must be considered twice as it can lead to big impact.
const routes: Routes = [
  // Pro part of the webview must be logged to access views.
  {
    path: 'pro',
    loadChildren: () => import('./features/products/pro/pro.module').then(m => m.ProModule),
    canActivate: [ContextGuard, BrowserGuard],
    resolve: {ProUrlResolver, company: CompanyResolver},
    data: {context: Context.PRO}
  },
  {
    path: 'scanner',
    loadChildren: () => import('./features/products/scanner/scanner.module').then(m => m.ScannerModule),
    canActivate: [ContextGuard, BrowserGuard],
    data: {context: Context.SCANNER},
    resolve: {company: CompanyResolver}
  },
  {
    path: 'report',
    loadChildren: () => import('./features/products/report/report.routes'),
    canActivate: [ContextGuard],
    data: {context: Context.REPORT},
    resolve: {company: CompanyResolver}
  },
  {
    path: 'appointment',
    loadChildren: () => import('./features/products/appointment/appointment.routes'),
    canActivate: [ContextGuard],
    data: {context: Context.APPOINTMENT},
    resolve: {company: CompanyResolver}
  },
  {
    path: 'diagnosis',
    loadChildren: () => import('./features/products/diagnosis/diagnosis.module').then(m => m.DiagnosisModule),
    canActivate: [ContextGuard, BrowserGuard],
    data: {context: Context.DIAGNOSIS},
    resolve: {company: CompanyResolver}
  },
  {
    path: 'not-supported', loadChildren: () => import('./features/not-supported/not-supported.module').then(m => m.NotSupportedModule),
    canActivate: [BrowserGuard]
  },
  {
    path: 'cookies', loadComponent: () => import('./shared/components/cookies-consent/cookies-consent.component').then(m => m.CookiesConsentComponent)
  },
  {path: ':fleet', redirectTo: 'diagnosis/:fleet', pathMatch:'prefix'},
  {path: '**', redirectTo: 'diagnosis', pathMatch: 'full'},

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true, enableTracing: false, canceledNavigationResolution: 'computed'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
